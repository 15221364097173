/* Dark theme overrides */
:root {
  color-scheme: dark;
}

body {
  background-color: #212529;
  color: #f8f9fa;
}

.form-control {
  background-color: #343a40 !important;
  color: #f8f9fa !important;
  border-color: #495057;
}

.form-control:focus {
  background-color: #343a40;
  color: #f8f9fa;
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.modal-content {
  background-color: #212529;
  color: #f8f9fa;
}

.modal-header {
  border-bottom-color: #495057;
}

.modal-footer {
  border-top-color: #495057;
}

/* Error modal specific styles */
.dark-theme .modal-content {
  background-color: #212529;
  color: #f8f9fa;
}

.dark-theme pre {
  background-color: #343a40;
  border-color: #495057;
}
