@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css');

/* Custom styles */
body {
  background-color: #1e1e1e;
  color: #e0e0e0;
}
.container {
  padding: 20px;
}

.converter-container {
  display: flex;
  gap: 40px;
}

textarea,
pre {
  background-color: #2d2d2d;
  color: #e0e0e0;
  border: 1px solid #444;
}

textarea {
  resize: vertical;
}

pre {
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.font-selector {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.font-selector select {
  background-color: #2d2d2d;
  color: #e0e0e0;
  border: 1px solid #444;
  margin-right: 10px;
}

.font-selector label {
  margin-right: 5px;
}

.modal-content {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .converter-container {
    flex-direction: column;
  }
}
